import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { faChevronDown, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Header(props) {
  const { language, auth, pageSelected, withLightBackground } = props;
  const { t } = useTranslation();

  const router = useRouter();
  const [offcanvas, setOffcanvas] = useState(false);

  const menuList = {
    links: [
      // {
      //   label: t('header.why_we'),
      //   //link: language === 'it' ? '/it#home-about-us' : '/it#home-about-us',
      //   onClick: () =>{
      //     if (document && document.getElementById('why-us')) {
      //       setOffcanvas(false);
      //       document.getElementById('why-us').scrollIntoView({
      //         behavior: 'smooth',
      //       });
      //     } else {
      //       window.location.href = '/#why-us';
      //     }
      //   },
      // },
      // {
      //   label: t('header.who_are_we'),
      //   //link: language === 'it' ? '/it#home-about-us' : '/it#home-about-us',
      //   onClick: () =>{
      //     if (document && document.getElementById('home-about-us')) {
      //       setOffcanvas(false);
      //       document.getElementById('home-about-us').scrollIntoView({
      //         behavior: 'smooth',
      //       });
      //     } else {
      //       window.location.href = '/#home-about-us';
      //     }
      //   },
      // },

    ],
  };

  function menuFunction(menu) {
    return menu.links.map((el, index) => {
      let isPageSelected = '';
      let hasSubmenu = false;
      if (el.label === pageSelected) {
        isPageSelected = ' menu-selected';
      }
      if (el.submenu && el.submenu.length > 0) {
        hasSubmenu = true;
      }
      return (
        <div key={`${index.toString()}`} className={`header-link ${isPageSelected}`}>
          {el.link ? (
            <Link href={el.link}>
              
                {el.label}
                {hasSubmenu ? <FontAwesomeIcon icon={faChevronDown} className="header-menu-icon" style={{ marginLeft: '5px' }} /> : null}
              
            </Link>
          ) : (
            <div
              className={`${el.onClick ? 'header_fake_link' : 'header_no_link'}`}
              style={el.onClick ? { cursor: 'pointer' } : {}}
              onClick={
                el.onClick
                  ? () => {
                      el.onClick();
                    }
                  : null
              }>
              {el.label}
              {hasSubmenu ? <FontAwesomeIcon icon={faChevronDown} className="header-menu-icon" style={{ marginLeft: '5px' }} /> : null}
            </div>
          )}
          {hasSubmenu ? (
            <div className="header-submenu">
              {el.submenu.map((subEl, subIndex) => {
                return (
                  <div key={`${index.toString()}-${subIndex.toString()}`} className={`header-link`}>
                    <Link href={subEl.link}>
                      {subEl.label}
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    });
  }

  return (
    <header className={`header ${offcanvas ? 'offcanvas-open' : ''} ${withLightBackground ? '' : 'blu-header-bg'}`}>
      <div className="header-container">
        <Link href={'/'} className={`logo_link `}>
          
          <img src="/logo_tassomutuo.png" className="logo onlydesktop" alt="TassoMutuo logo" />
          <img src={`/${pageSelected === 'home' ? 'home' : ''}logo_tassomutuo_dark.png`} className="logo onlymobile" alt="TassoMutuo logo" />
          
        </Link>
        <div className="header-menu-container">{menuFunction(menuList)}</div>
        {/* <div className="header-hamburger">
          <button
            className="hamburger-button"
            onClick={() => {
              setOffcanvas(!offcanvas);
            }}>
            <span className={`hamburger-button-inner ${offcanvas ? 'hamburger-closure' : ''}`}> </span>
          </button>
        </div> */}
        <div className="header-phone headerposition">
          <a className="header-phone-link" href={`tel:3490516905`}>
            <FontAwesomeIcon icon={faPhone} className="header-contact-icon" />
            <span className="labelassistenza onlydesktop">Assistenza gratuita <b>3490516905</b></span>
            <span className="labelassistenza onlymobile"><b>ASSISTENZA</b></span>
          </a>
        </div>
      </div>
      {offcanvas ? (
        <div className="offcanvas-menu-container">
          <style>{'body {overflow: hidden;}'}</style>
          {menuFunction(menuList)}
          <div className="offcanvas-phone-container">
            <a className="offcanvas-phone header-phone-link" href={`mailto:tassomutuo@euroansa.it`}>
              <FontAwesomeIcon icon={faEnvelope} className="header-contact-icon" />
              tassomutuo@euroansa.it
            </a>
          </div>
          <div className="header-phone"><br/><br/>
            <a className="header-phone-link" href={`tel:3490516905`}>
              <FontAwesomeIcon icon={faPhone} className="header-contact-icon" />
              Assistenza gratuita 3490516905
            </a>
          </div>
        </div>
      ) : null}
    </header>
  );
}
