// pages/404.js
import React from 'react';
import Header from '../components/Header';

const MAX_WIDTH = '1600px';

const Custom404 = () => {
  return (
    <>
      <Header pageSelected="" />
      <div style={{ padding: '120px 20px 20px 20px' }}>
        <div style={{ margin: '50px auto', minHeight:'30vh', textAlign:'center', paddingTop:'40px', maxWidth: MAX_WIDTH }}>

            <h1>404 - Pagina non trovata</h1>
            <p>La pagina che stai cercando potrebbe non esistere o essere stata spostata.</p>

        </div>
      </div>
    </>

  );
};

export default Custom404;
